declare module "#app" {
  interface NuxtApp {
    $api: typeof $fetch
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const api = $fetch.create({
    baseURL: config.public.baseURL,
    credentials: 'include',

    onResponseError({ response }) {
      if ([403, 404, 500].includes(response.status)) {
        showError({
          message: response._data.detail,
          statusCode: response.status,
        })
        return
      }
    },
  })

  return {
    provide: {
      api
    }
  }
})
