import * as Sentry from '@sentry/nuxt'
import type {RouteLocationRaw} from 'vue-router'

export default function useAuth() {
  const account = useState<Account|null>('auth_account', () => null)
  const isAuthenticated = computed<boolean>(() => !!account.value)

  const logout = async (to?: RouteLocationRaw) => {
    const {$api} = useNuxtApp()

    await $api('/token/delete/', {
      method: 'POST',
    })
      .then(async () => {
        const {fullPath} = useRoute()
        clearNuxtState('auth_account')
        Sentry.setUser(null)
        if (fullPath === to) {
          reloadNuxtApp()
        } else {
          await navigateTo(to ?? { name: 'index'})
        }
      })
  }

  const setAccount = async () => {
    const {$api} = useNuxtApp()
    await $api<Account>('/account/')
      .then(response => {
        if (!response) return
        account.value = response
        Sentry.setUser({email: account.value.email})
      })
  }

  return {
    account,
    isAuthenticated,
    logout,
    setAccount,
  }
}
