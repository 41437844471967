import { default as changei2J0Z2fQ0CMeta } from "/tmp/build_817c6993/pages/account/change.vue?macro=true";
import { default as indexNgyw3t5QVjMeta } from "/tmp/build_817c6993/pages/account/index.vue?macro=true";
import { default as loging5q3YHi33qMeta } from "/tmp/build_817c6993/pages/account/login.vue?macro=true";
import { default as _91token_93DR0CdZt5lDMeta } from "/tmp/build_817c6993/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as complete17RQYTXuonMeta } from "/tmp/build_817c6993/pages/account/password-reset/complete.vue?macro=true";
import { default as done8nrB4nDVKPMeta } from "/tmp/build_817c6993/pages/account/password-reset/done.vue?macro=true";
import { default as indexv1gLHNAFdwMeta } from "/tmp/build_817c6993/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetkYm5IggzdsMeta } from "/tmp/build_817c6993/pages/account/password-reset.vue?macro=true";
import { default as password6hWRoI1zdmMeta } from "/tmp/build_817c6993/pages/account/password.vue?macro=true";
import { default as registerNBjqeQegrxMeta } from "/tmp/build_817c6993/pages/account/register.vue?macro=true";
import { default as contactpSs63BKJQqMeta } from "/tmp/build_817c6993/pages/contact.vue?macro=true";
import { default as cookie_45policyeNp2NXwBXOMeta } from "/tmp/build_817c6993/pages/docs/cookie-policy.vue?macro=true";
import { default as privacy_45policyFvRu8VxEwUMeta } from "/tmp/build_817c6993/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45useQKVAJn2hPlMeta } from "/tmp/build_817c6993/pages/docs/terms-of-use.vue?macro=true";
import { default as _91slug_93HxMaUVfoSyMeta } from "/tmp/build_817c6993/pages/events/[slug].vue?macro=true";
import { default as indexNoRqzPuAKfMeta } from "/tmp/build_817c6993/pages/events/index.vue?macro=true";
import { default as indexdjSa2UPXAYMeta } from "/tmp/build_817c6993/pages/index.vue?macro=true";
import { default as _91id_93ydppjsf3PuMeta } from "/tmp/build_817c6993/pages/orders/[id].vue?macro=true";
import { default as indexwpxfU2XiqoMeta } from "/tmp/build_817c6993/pages/orders/index.vue?macro=true";
import { default as indexTODyK5Zo7FMeta } from "/tmp/build_817c6993/pages/purchase/index.vue?macro=true";
export default [
  {
    name: "account-change",
    path: "/account/change",
    meta: changei2J0Z2fQ0CMeta || {},
    component: () => import("/tmp/build_817c6993/pages/account/change.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexNgyw3t5QVjMeta || {},
    component: () => import("/tmp/build_817c6993/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loging5q3YHi33qMeta || {},
    component: () => import("/tmp/build_817c6993/pages/account/login.vue")
  },
  {
    name: password_45resetkYm5IggzdsMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetkYm5IggzdsMeta || {},
    component: () => import("/tmp/build_817c6993/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/tmp/build_817c6993/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/tmp/build_817c6993/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/tmp/build_817c6993/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/tmp/build_817c6993/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: password6hWRoI1zdmMeta || {},
    component: () => import("/tmp/build_817c6993/pages/account/password.vue")
  },
  {
    name: "account-register",
    path: "/account/register",
    meta: registerNBjqeQegrxMeta || {},
    component: () => import("/tmp/build_817c6993/pages/account/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_817c6993/pages/contact.vue")
  },
  {
    name: "docs-cookie-policy",
    path: "/docs/cookie-policy",
    component: () => import("/tmp/build_817c6993/pages/docs/cookie-policy.vue")
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/tmp/build_817c6993/pages/docs/privacy-policy.vue")
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/tmp/build_817c6993/pages/docs/terms-of-use.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/tmp/build_817c6993/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/tmp/build_817c6993/pages/events/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_817c6993/pages/index.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93ydppjsf3PuMeta || {},
    component: () => import("/tmp/build_817c6993/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexwpxfU2XiqoMeta || {},
    component: () => import("/tmp/build_817c6993/pages/orders/index.vue")
  },
  {
    name: "purchase",
    path: "/purchase",
    component: () => import("/tmp/build_817c6993/pages/purchase/index.vue")
  }
]